import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import { Page } from '../../components';
import { ConnectedDriverResults } from '../../connectors';
import useTrans from '../../hooks/useTrans';
import { RoutePaths } from '../../Routes';

import './DriverResultsPage.scss';

const DriverResultsPage: FC = (): ReactElement => {
    const trans = useTrans();
    const navigate = useNavigate();

    const onPreviousClick = (): void => {
        navigate(RoutePaths.driverStandings());
    };

    return (
        <Page
            title={trans('pages.driverResults.heading')}
            onPreviousClick={onPreviousClick}
            className="driver-results-page"
        >
            <Helmet>
                <title>{trans('pages.driverResults.meta.title')}</title>

                <meta property="og:title" content={trans('pages.driverResults.meta.title')} />
                <meta name="description" content={trans('pages.driverResults.meta.description')} />
                <meta property="og:description" content={trans('pages.driverResults.meta.description')} />
            </Helmet>

            <ConnectedDriverResults className="driver-results-page__content" />
        </Page>
    );
};

export default DriverResultsPage;
