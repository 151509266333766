import { combineReducers } from 'redux';

import appReducer, { AppState } from './app/appReducer';
import calendarReducer, { CalendarState } from './calendar/calendarReducer';
import chatReducer, { ChatState } from './chat/chatReducer';
import constructorStandingsReducer, { ConstructorStandingsState } from './constructorStandings/constructorStandingsReducer';
import driverResultsReducer, { DriverResultsState } from './driverResults/driverResultsReducer';
import driverStandingsReducer, { DriverStandingsState } from './driverStandings/driverStandingsReducer';
import nextYearCalendarReducer, { NextYearCalendarState } from './nextYearCalendar/nextYearCalendarReducer';
import publicApisReducer, { PublicApisState } from './publicApis/publicApisReducer';
import qualifyingResultReducer, { QualifyingResultState } from './qualifyingResult/qualifyingResultReducer';
import raceResultReducer, { RaceResultState } from './raceResult/raceResultReducer';
import raceWeekendReducer, { RaceWeekendState } from './raceWeekend/raceWeekendReducer';
import sprintQualifyingResultReducer, { SprintQualifyingResultState } from './sprintQualifyingResult/sprintQualifyingResultReducer';
import sprintRaceResultReducer, { SprintRaceResultState } from './sprintRaceResult/sprintRaceResultReducer';

export interface Reducers {
    appReducer: AppState;
    calendarReducer: CalendarState;
    chatReducer: ChatState;
    constructorStandingsReducer: ConstructorStandingsState,
    driverResultsReducer: DriverResultsState;
    driverStandingsReducer: DriverStandingsState;
    nextYearCalendarReducer: NextYearCalendarState;
    publicApisReducer: PublicApisState;
    qualifyingResultReducer: QualifyingResultState;
    raceResultReducer: RaceResultState;
    raceWeekendReducer: RaceWeekendState;
    sprintQualifyingResultReducer: SprintQualifyingResultState;
    sprintRaceResultReducer: SprintRaceResultState;
}

export default combineReducers<Reducers>({
    appReducer,
    calendarReducer,
    chatReducer,
    constructorStandingsReducer,
    driverResultsReducer,
    driverStandingsReducer,
    nextYearCalendarReducer,
    publicApisReducer,
    qualifyingResultReducer,
    raceResultReducer,
    raceWeekendReducer,
    sprintQualifyingResultReducer,
    sprintRaceResultReducer,
});
