export interface DriverResource {
    driverId: '',
    permanentNumber: string;
    code: string;
    url: string;
    givenName: string;
    familyName: string;
    dateOfBirth: string;
    nationality: string;
}

export interface Driver {
    id: string;
    firstName: string;
    lastName: string;
    abbreviation: string;
    nationality: string;
    nationalityAbbreviation?: string;
    number: number;
}

export const defaultDriver = (): Driver => ({
    id: '',
    firstName: '',
    lastName: '',
    abbreviation: '',
    nationality: '',
    number: 0,
});
