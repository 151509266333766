import { getDriverResultsApiCall } from '../../entities/DriverResults/DriverResultsService';
import { FetchResultType, isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import { setDriverResults, setError, setIsLoading } from './driverResultsReducer';

export const fetchDriverResults = (driverId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(undefined));

    try {
        const driverResultsResponse = await getDriverResultsApiCall(driverId);

        if (!isFetchCollectionResultSuccessful(driverResultsResponse)) {
            if (driverResultsResponse.type === FetchResultType.Abort) {
                return;
            }

            dispatch(setError({
                status: driverResultsResponse.status,
                title: driverResultsResponse.error,
            }));

            return;
        }

        dispatch(setDriverResults(driverResultsResponse.data));
    } catch (error) {
        console.error('[fetchDriverResults]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
