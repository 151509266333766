import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { StandingsListItem } from '../../compositions';
import { DriverStandings } from '../../entities/DriverStandings/DriverStandings';

import './DriverStandingsList.scss';

interface DriverStandingsListProps {
    driverStandings: DriverStandings;
    className?: string;
}

const DriverStandingsList: FC<DriverStandingsListProps> = ({
    driverStandings,
    className = '',
}): ReactElement => (
    <ul className={`driver-standings-list ${className}`}>
        {driverStandings.standings.map(standing => {
            const listItemClassNames = classNames('driver-standings-list__list-item', {
                'driver-standings-list__list-item--is-champion': standing.isChampion,
            });

            return (
                <StandingsListItem
                    key={standing.position}
                    isChampion={standing.isChampion}
                    position={standing.position}
                    flag={standing.driver.nationalityAbbreviation}
                    title={`${standing.driver.firstName} ${standing.driver.lastName}`}
                    subTitle={standing.constructor.name}
                    points={standing.points}
                    className={listItemClassNames}
                />
            );
        })}
    </ul>
);

export default DriverStandingsList;
