import { sprintRounds } from '../../constants';
import { transformToCircuit } from '../Circuit/CircuitTransformers';
import { transformToConstructor } from '../Constructor/ConstructorTransformers';
import { transformToDriver } from '../Driver/DriverTransformers';
import { isFetchCollectionResultSuccessful } from '../FetchResult';
import { getSprintRaceResultApiCall } from '../SprintRaceResult/SprintRaceResultService';
import {
    DriverResult,
    DriverResultResource,
    DriverResults,
    DriverResultsResource,
} from './DriverResults';

export const transformToDriverResult = (
    driverResultResource: DriverResultResource,
): DriverResult => {
    const position = driverResultResource.Results[0].positionText === 'R'
        ? 'DNF'
        : driverResultResource.Results[0].positionText;

    return ({
        circuit: transformToCircuit(driverResultResource.Circuit),
        raceName: driverResultResource.raceName,
        constructor: transformToConstructor(driverResultResource.Results[0].Constructor),
        position,
        points: Number(driverResultResource.Results[0].points),
    });
};

export const transformToDriverResults = (
    driverResultsResource: DriverResultsResource,
): DriverResults => {
    const resource = driverResultsResource.MRData.RaceTable;
    const results: DriverResult[] = [];

    resource.Races.forEach(async race => {
        const round = Number(race.round);

        if (sprintRounds.includes(round)) {
            const sprintRaceResultResponse = await getSprintRaceResultApiCall(round);

            if (!isFetchCollectionResultSuccessful(sprintRaceResultResponse)) {
                return [];
            }

            const driverSprintResult = sprintRaceResultResponse.data.filter(result => result.driver.id === race.Results[0].Driver.driverId);

            results.push({
                circuit: transformToCircuit(race.Circuit),
                raceName: `${race.raceName} (Sprint)`,
                constructor: transformToConstructor(race.Results[0].Constructor),
                position: String(driverSprintResult[0].position),
                points: driverSprintResult[0].points,
            });
        }

        return results.push(transformToDriverResult(race));
    });

    return ({
        season: Number(resource.season),
        driver: transformToDriver(resource.Races[0].Results[0].Driver),
        results,
    });
};
