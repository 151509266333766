import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { defaultDriverResults, DriverResults } from '../../entities/DriverResults/DriverResults';
import { Error } from '../../helpers/japi/types/Error';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type DriverResultsState = AsyncReduxState<{
    results: DriverResults;
}>;

const initialState: DriverResultsState = {
    ...initialAsyncReduxState,
    results: defaultDriverResults(),
};

const driverResultsSlice = createSlice({
    name: 'driverResultsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DriverResultsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): DriverResultsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setDriverResults(state, action: PayloadAction<DriverResults>): DriverResultsState {
            return {
                ...state,
                results: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setDriverResults,
} = driverResultsSlice.actions;

export default driverResultsSlice.reducer;
