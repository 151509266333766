import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import trans from '../../helpers/trans';
import { RoutePaths } from '../../Routes';

import './StandingsListItem.scss';

interface StandingsListItemProps {
    isChampion?: boolean;
    position: number;
    flag?: string;
    title: string;
    subTitle?: string;
    points: number;
    className?: string;
}

const StandingsListItem: FC<StandingsListItemProps> = ({
    isChampion = false,
    position,
    flag = '',
    title,
    subTitle = '',
    points,
    className = '',
}): ReactElement => {
    const location = useLocation();

    const navLinkTo = location.pathname === RoutePaths.driverStandings()
        // ? RoutePaths.driverResults(id)
        ? RoutePaths.driverStandings()
        : RoutePaths.constructorStandings();

    const positionClassNames = classNames('standings-list-item__position', {
        'standings-list-item__position--is-champion': isChampion,
    });

    const pointsClassNames = classNames('standings-list-item__points', {
        'standings-list-item__points--is-champion': isChampion,
    });

    return (
        <li className={`standings-list-item ${className}`}>
            <NavLink
                to={navLinkTo}
                title={trans('compositions.standingsListItem.toDetail')}
                className="standings-list-item__link"
            >
                <div className="standings-list-item__content">
                    <p className={positionClassNames}>{position}</p>

                    {flag && <span className={`fi fi-${flag} standings-list-item__flag`} />}

                    <div className="standings-list-item__title-wrapper">
                        <p className="standings-list-item__title">{title}</p>
                        {subTitle && <p className="standings-list-item__sub-title">{subTitle}</p>}
                    </div>
                </div>

                <p className={pointsClassNames}>{points}</p>
            </NavLink>
        </li>
    );
};

export default StandingsListItem;
