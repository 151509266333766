import { FC, ReactElement } from 'react';

import { DriverResult } from '../../../../entities/DriverResults/DriverResults';
import trans from '../../../../helpers/trans';

import './DriverResultsListItem.scss';

interface DriverResultsListItemProps {
    result: DriverResult;
    className?: string;
}

const DriverResultsListItem: FC<DriverResultsListItemProps> = ({
    result,
    className = '',
}): ReactElement => {
    const { countryAbbreviation } = result.circuit.location;

    return (
        <li className={`driver-results-list-item ${className}`}>
            <div className="driver-results-list-item__location">
                <p>
                    {countryAbbreviation && (
                        <span className={`fi fi-${countryAbbreviation} driver-results-list-item__flag`} />
                    )}
                    {trans('compositions.driverResultListItem.location', {
                        country: result.circuit.location.country,
                        locality: result.circuit.location.locality,
                    })}
                </p>

                <p className="driver-results-list-item__constructor">{result.constructor.name}</p>
            </div>

            <div className="driver-results-list-item__results">
                <div className="driver-results-list-item__result">
                    <p className="driver-results-list-item__position-label">Position:</p>
                    <p className="driver-results-list-item__position">{result.position}</p>
                </div>
                <div className="driver-results-list-item__result">
                    <p className="driver-results-list-item__points-label">Points:</p>
                    <p className="driver-results-list-item__points">{result.points}</p>
                </div>
            </div>
        </li>
    );
};

export default DriverResultsListItem;
