import { Constructor, ConstructorResource } from '../Constructor/Constructor';
import { Driver, DriverResource } from '../Driver/Driver';

export interface StandingsResource {
    position: string;
    positionText: string;
    points: string;
    wins: string;
    Driver: DriverResource;
    Constructors: ConstructorResource[];
}

export interface DriverStandingsResource {
    MRData: {
        StandingsTable: {
            season: string;
            StandingsLists: {
                season: string;
                round: string;
                DriverStandings: StandingsResource[];
            }[];
        };
    };
}

export interface Standing {
    isChampion: boolean;
    position: number;
    points: number;
    driver: Driver;
    constructor: Constructor;
}

export interface DriverStandings {
    season: number;
    standings: Standing[];
}

export const defaultDriverStandings = (): DriverStandings => ({
    season: new Date().getFullYear(),
    standings: [],
});
