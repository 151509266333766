import { Circuit, CircuitResource } from '../Circuit/Circuit';
import { Constructor } from '../Constructor/Constructor';
import { defaultDriver, Driver } from '../Driver/Driver';
import { RaceResultResource } from '../RaceResult/RaceResult';

export interface DriverResultResource {
    season: string;
    round: string;
    url: string;
    raceName: string;
    date: string;
    time: string;
    Circuit: CircuitResource;
    Results: RaceResultResource[];
}

export interface DriverResultsResource {
    MRData: {
        RaceTable: {
            season: string;
            Races: DriverResultResource[];
        };
    };
}

export interface DriverResult {
    raceName: string;
    circuit: Circuit;
    constructor: Constructor;
    position: string;
    points: number;
}

export interface DriverResults {
    season: number;
    driver: Driver;
    results: DriverResult[];
}

export const defaultDriverResults = (): DriverResults => ({
    season: new Date().getFullYear(),
    driver: defaultDriver(),
    results: [],
});
