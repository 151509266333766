import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { ErrorLabel, LoadingSpinner } from '../../components';
import { DriverResultsList } from '../../containers';
import { fetchDriverResults } from '../../redux/driverResults/driverResultsActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedDriverResultsProps {
    className?: string;
}

const ConnectedDriverResults: FC<ConnectedDriverResultsProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { driverId } = useParams();

    const isLoading = useTypedSelector(state => state.driverResultsReducer.isLoading);
    const error = useTypedSelector(state => state.driverResultsReducer.error);
    const driverResults = useTypedSelector(state => state.driverResultsReducer.results);

    useEffect((): void => {
        if (driverId) dispatch(fetchDriverResults(driverId));
    }, [driverId]);

    return (
        <div className={className}>
            {isLoading && <LoadingSpinner />}

            {(!isLoading && error?.title) && <ErrorLabel text={error.title} />}

            {(!isLoading && driverResults.results.length > 0) && (
                <DriverResultsList driverResults={driverResults} />
            )}
        </div>
    );
};

export default ConnectedDriverResults;
