import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { ErrorLabel } from '../../components';
import { sprintRounds } from '../../constants';
import { RaceWeekendInfo } from '../../containers';
import { RaceWeekendInfoSkeletons } from '../../containers/RaceWeekendInfo/subcomponents';
import { getDifferenceInDays } from '../../helpers/date';
import useUnmount from '../../hooks/useUnmount';
import { fetchCalendar } from '../../redux/calendar/calendarActions';
import { fetchQualifyingResult } from '../../redux/qualifyingResult/qualifyingResultActions';
import { resetQualifyingResultState } from '../../redux/qualifyingResult/qualifyingResultReducer';
import { fetchRaceResult } from '../../redux/raceResult/raceResultActions';
import { resetRaceResultState } from '../../redux/raceResult/raceResultReducer';
import { fetchRaceWeekend } from '../../redux/raceWeekend/raceWeekendActions';
import { resetRaceWeekendState } from '../../redux/raceWeekend/raceWeekendReducer';
import { fetchSprintQualifyingResult } from '../../redux/sprintQualifyingResult/sprintQualifyingResultActions';
import { resetSprintQualifyingResultState } from '../../redux/sprintQualifyingResult/sprintQualifyingResultReducer';
import { fetchSprintRaceResult } from '../../redux/sprintRaceResult/sprintRaceResultActions';
import { resetSprintRaceResultState } from '../../redux/sprintRaceResult/sprintRaceResultReducer';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedRaceWeekendProps {
    className?: string;
}

const ConnectedRaceWeekend: FC<ConnectedRaceWeekendProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { round } = useParams();

    const raceWeekendIsLoading = useTypedSelector(state => state.raceWeekendReducer.isLoading);
    const raceWeekendError = useTypedSelector(state => state.raceWeekendReducer.error);
    const raceWeekend = useTypedSelector(state => state.raceWeekendReducer.raceWeekend);

    const calendar = useTypedSelector(state => state.calendarReducer.calendar);

    const raceResultIsLoading = useTypedSelector(state => state.raceResultReducer.isLoading);
    const raceResultError = useTypedSelector(state => state.raceResultReducer.error);
    const raceResult = useTypedSelector(state => state.raceResultReducer.raceResult);

    const sprintRaceResultIsLoading = useTypedSelector(state => state.sprintRaceResultReducer.isLoading);
    const sprintRaceResultError = useTypedSelector(state => state.sprintRaceResultReducer.error);
    const sprintRaceResult = useTypedSelector(state => state.sprintRaceResultReducer.sprintRaceResult);

    const qualifyingResultIsLoading = useTypedSelector(state => state.qualifyingResultReducer.isLoading);
    const qualifyingResultError = useTypedSelector(state => state.qualifyingResultReducer.error);
    const qualifyingResult = useTypedSelector(state => state.qualifyingResultReducer.qualifyingResult);

    const sprintQualifyingResultIsLoading = useTypedSelector(state => state.sprintQualifyingResultReducer.isLoading);
    const sprintQualifyingResultError = useTypedSelector(state => state.sprintQualifyingResultReducer.error);
    const sprintQualifyingResult = useTypedSelector(state => state.sprintQualifyingResultReducer.sprintQualifyingResult);

    const showRaceTab = getDifferenceInDays(new Date(), raceWeekend.race.startDateTime) <= 0;
    const showSprintRaceTab = !!raceWeekend.race.sprint && getDifferenceInDays(new Date(), raceWeekend.race.sprint.startDateTime) <= 0;
    const showQualifyingTab = getDifferenceInDays(new Date(), raceWeekend.race.qualifying.startDateTime) <= 0;
    const showSprintQualifyingTab = !!raceWeekend.race.sprint && getDifferenceInDays(new Date(), raceWeekend.race.secondPractice.startDateTime) <= 0;

    useEffect((): void => {
        dispatch(fetchRaceWeekend(Number(round)));
        dispatch(fetchRaceResult(Number(round)));
        dispatch(fetchQualifyingResult(Number(round)));

        if (sprintRounds.includes(Number(round))) {
            dispatch(fetchSprintRaceResult(Number(round)));
            dispatch(fetchSprintQualifyingResult(Number(round)));
        }

        if (calendar.races.length === 0) dispatch(fetchCalendar());
    }, [round]);

    useUnmount((): void => {
        dispatch(resetRaceWeekendState());
        dispatch(resetRaceResultState());
        dispatch(resetSprintRaceResultState());
        dispatch(resetQualifyingResultState());
        dispatch(resetSprintQualifyingResultState());
    });

    return (
        <div className={className}>
            {raceWeekendIsLoading && <RaceWeekendInfoSkeletons />}

            {(!raceWeekendIsLoading && raceWeekendError?.title) && <ErrorLabel text={raceWeekendError.title} />}

            {!raceWeekendIsLoading && (
                <RaceWeekendInfo
                    raceResultIsLoading={raceResultIsLoading}
                    sprintRaceResultIsLoading={sprintRaceResultIsLoading}
                    qualifyingResultIsLoading={qualifyingResultIsLoading}
                    sprintQualifyingResultIsLoading={sprintQualifyingResultIsLoading}
                    isUpcoming={calendar.upcomingRound === raceWeekend.race.round}
                    showRaceTab={showRaceTab}
                    showSprintRaceTab={showSprintRaceTab}
                    showQualifyingTab={showQualifyingTab}
                    showSprintQualifyingTab={showSprintQualifyingTab}
                    raceResultError={raceResultError}
                    sprintRaceResultError={sprintRaceResultError}
                    qualifyingResultError={qualifyingResultError}
                    sprintQualifyingResultError={sprintQualifyingResultError}
                    raceWeekend={raceWeekend}
                    raceResult={raceResult}
                    sprintRaceResult={sprintRaceResult}
                    qualifyingResult={qualifyingResult}
                    sprintQualifyingResult={sprintQualifyingResult}
                />
            )}
        </div>
    );
};

export default ConnectedRaceWeekend;
