import { FC, ReactElement } from 'react';

import { DriverResults } from '../../entities/DriverResults/DriverResults';
import { DriverResultsListItem } from './subcomponents';

import './DriverResultsList.scss';

interface DriverResultsListProps {
    driverResults: DriverResults;
    className?: string;
}

const DriverResultsList: FC<DriverResultsListProps> = ({
    driverResults,
    className = '',
}): ReactElement => (
    <div className={`driver-results-list ${className}`}>
        <h2 className="driver-results-list__driver-name">
            {`${driverResults.driver.firstName} ${driverResults.driver.lastName}`}
        </h2>

        <ul className="driver-results-list__list">
            {driverResults.results.map(result => (
                <DriverResultsListItem
                    key={result.raceName}
                    result={result}
                    className="driver-results-list__list-item"
                />
            ))}
        </ul>
    </div>
);

export default DriverResultsList;
