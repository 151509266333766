import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { StandingsListItem } from '../../compositions';
import { ConstructorStandings } from '../../entities/ConstructorStandings/ConstructorStandings';

import './ConstructorStandingsList.scss';

interface ConstructorStandingsListProps {
    constructorStandings: ConstructorStandings;
    className?: string;
}

const ConstructorStandingsList: FC<ConstructorStandingsListProps> = ({
    constructorStandings,
    className = '',
}): ReactElement => (
    <ul className={`constructor-standings-list ${className}`}>
        {constructorStandings.standings.map(standing => {
            const listItemClassNames = classNames('constructor-standings-list__list-item', {
                'constructor-standings-list__list-item--is-champion': standing.isChampion,
            });

            return (
                <StandingsListItem
                    key={standing.position}
                    isChampion={standing.isChampion}
                    position={standing.position}
                    flag={standing.constructor.nationalityAbbreviation}
                    title={standing.constructor.name}
                    points={standing.points}
                    className={listItemClassNames}
                />
            );
        })}
    </ul>
);

export default ConstructorStandingsList;
