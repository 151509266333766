import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import { IconButton } from '../../../../compositions';
import useScrollDirection from '../../../../hooks/useScrollDirection';
import { RoutePaths } from '../../../../Routes';
import Logo from '../../../Logo/Logo';

import './PageHeader.scss';

interface PageHeaderProps {
    title: string;
    onPreviousClick?: () => void;
    className?: string;
}

const PageHeader: FC<PageHeaderProps> = ({
    title,
    onPreviousClick,
    className = '',
}): ReactElement => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isScrollingDown, isPastMenu } = useScrollDirection();

    const onSettingsButtonClick = (): void => {
        if (location.pathname === RoutePaths.settings()) {
            navigate(-1);
        } else {
            navigate(RoutePaths.settings());
        }
    };

    const pageHeaderClassNames = classNames('page-header', {
        'page-header--is-scrolling-down': isScrollingDown && isPastMenu,
    }, className);

    return (
        <header className={pageHeaderClassNames}>
            <div className="page-header__title-wrapper">
                {!!onPreviousClick && (
                    <IconButton
                        hideLabel
                        icon="arrow-left"
                        text="Back to calendar"
                        onClick={onPreviousClick}
                        className="page-header__previous-button"
                    />
                )}
                <h1 className="page-header__title">{title}</h1>
            </div>

            <div className="page-header__actions">
                <IconButton
                    hideLabel
                    icon="gear"
                    text="Settings"
                    onClick={onSettingsButtonClick}
                    iconClassName="page-header__settings-button-icon"
                    className="page-header__settings-button"
                />
                <Logo className="page-header__logo" />
            </div>
        </header>
    );
};

export default PageHeader;
