import { addHours } from '../../helpers/date';
import { transformToCircuit } from '../Circuit/CircuitTransformers';
import { transformToSession } from '../Session/SessionTransformers';
import { Race, RaceResource } from './Race';

export const transformToRace = (
    raceResource: RaceResource,
): Race => {
    let customStartDateTime;
    let customEndDateTime;
    let customQualifying;
    let customSprint;
    let customThirdPractice;
    let customSecondPractice;
    let customFirstPractice;

    if (Number(raceResource.round) === 22) {
        customStartDateTime = new Date('2024-11-24T06:00:00Z');
        customEndDateTime = new Date('2024-11-24T08:00:00Z');
        customQualifying = {
            date: '2024-11-23',
            time: '06:00:00Z',
        };
        customThirdPractice = {
            date: '2024-11-23',
            time: '02:30:00Z',
        };
        customSecondPractice = {
            date: '2024-11-22',
            time: '06:00:00Z',
        };
        customFirstPractice = {
            date: '2024-11-22',
            time: '02:30:00Z',
        };
    }

    if (Number(raceResource.round) === 23) {
        customStartDateTime = new Date('2024-12-01T16:00:00Z');
        customEndDateTime = new Date('2024-12-01T18:00:00Z');
        customQualifying = {
            date: '2024-11-30',
            time: '18:00:00Z',
        };
        customSprint = {
            date: '2024-11-30',
            time: '14:00:00Z',
        };
        customSecondPractice = {
            date: '2024-11-29',
            time: '17:30:00Z',
        };
        customFirstPractice = {
            date: '2024-11-29',
            time: '13:30:00Z',
        };
    }

    const combinedDateTime = `${raceResource.date}T${raceResource.time}`;
    const startDateTime = new Date(combinedDateTime);

    const thirdPracticeResource = customThirdPractice || raceResource.ThirdPractice;
    const sprintResource = customSprint || raceResource.Sprint;

    return ({
        round: Number(raceResource.round),
        name: raceResource.raceName,
        startDateTime: customStartDateTime || startDateTime,
        endDateTime: customEndDateTime || addHours(new Date(combinedDateTime), 2),
        circuit: transformToCircuit(raceResource.Circuit),
        firstPractice: transformToSession(customFirstPractice || raceResource.FirstPractice),
        secondPractice: transformToSession(customSecondPractice || raceResource.SecondPractice),
        thirdPractice: thirdPracticeResource
            ? transformToSession(thirdPracticeResource)
            : undefined,
        qualifying: transformToSession(customQualifying || raceResource.Qualifying),
        sprint: sprintResource
            ? transformToSession(sprintResource)
            : undefined,
    });
};

