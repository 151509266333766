import { transformToConstructor } from '../Constructor/ConstructorTransformers';
import { transformToDriver } from '../Driver/DriverTransformers';
import {
    DriverStandings,
    DriverStandingsResource,
    Standing,
    StandingsResource,
} from './DriverStandings';

export const transformToStanding = (
    standingsResource: StandingsResource,
    season: string,
): Standing => {
    const isChampion = season === '2024' && standingsResource.Driver.code === 'VER';

    return ({
        isChampion,
        position: Number(standingsResource.position),
        points: Number(standingsResource.points),
        driver: transformToDriver(standingsResource.Driver),
        constructor: transformToConstructor(standingsResource.Constructors[0]),
    });
};

export const transformToDriverStandings = (
    driverStandingsResource: DriverStandingsResource,
): DriverStandings => {
    const standingsTabelResource = driverStandingsResource.MRData.StandingsTable.StandingsLists[0];
    const standings = standingsTabelResource.DriverStandings.map(standingResource => (
        transformToStanding(standingResource, driverStandingsResource.MRData.StandingsTable.season)
    ));

    return ({
        season: Number(standingsTabelResource.season),
        standings,
    });
};
