import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';

import { Page } from '../../components';
import { ConnectedSettings } from '../../connectors';
import useTrans from '../../hooks/useTrans';
import { RoutePaths } from '../../Routes';

import './SettingsPage.scss';

const SettingsPage: FC = (): ReactElement => {
    const trans = useTrans();
    const location = useLocation();
    const navigate = useNavigate();

    const onPreviousClick = (): void => {
        if (location.pathname === RoutePaths.settings()) {
            navigate(-1);
        } else {
            navigate(RoutePaths.settings());
        }
    };

    return (
        <Page
            title={trans('pages.settings.heading')}
            onPreviousClick={onPreviousClick}
            className="settings-page"
        >
            <Helmet>
                <title>{trans('pages.settings.meta.title')}</title>

                <meta property="og:title" content={trans('pages.settings.meta.title')} />
                <meta name="description" content={trans('pages.settings.meta.description')} />
                <meta property="og:description" content={trans('pages.settings.meta.description')} />
            </Helmet>

            <ConnectedSettings className="settings-page__content" />
        </Page>
    );
};

export default SettingsPage;
