import { isSSR } from '../../helpers';
import { isDOMException } from '../../helpers/error';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import { FetchCollectionResult, FetchResultType, rejectFetchError } from '../FetchResult';
import { DriverResults, DriverResultsResource } from './DriverResults';
import { transformToDriverResults } from './DriverResultsTransformers';

let driverResultsAbortController = new AbortController();

export const getDriverResultsApiCall = async (driverId: string): Promise<FetchCollectionResult<DriverResults, string>> => {
    if (!isSSR) {
        driverResultsAbortController.abort();
        driverResultsAbortController = new AbortController();
    }

    try {
        const url = generateApiUrl({
            endpoint: `/current/drivers/${driverId}/results.json`,
        });

        const response = await fetch(url, {
            signal: driverResultsAbortController.signal,
        });

        if (!response.ok) {
            return rejectFetchError('getDriverResultsApiCall', response);
        }

        const driverResultsResource: DriverResultsResource = await response.json();
        const driverResults = transformToDriverResults(driverResultsResource);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: driverResults,
        };
    } catch (error: unknown) {
        console.error('[getDriverResultsApiCall]', error);

        if (isDOMException(error) && error.name === 'AbortError') {
            return {
                type: FetchResultType.Abort,
                error: trans('errors.userAborted'),
            };
        }

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
